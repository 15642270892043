
@media (min-width:290px) and (max-width:440px) {
	svg {
    overflow: hidden;
        vertical-align: middle;
        width: 18px;
        height: 18px;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.css-79xub {
            left: 0;
        width: 100px ;
        min-width: 18px !important;
   
}

.wishlist {
    padding: 0 16px !important;
    width: 100% !important;
    height: auto;
    margin: 24px auto 16px !important;
        min-height: 28.72vh;
}
.wish__card__container {
    width: 100%;
}
.wishlist__container nav.breadcrumbs {
    display: none !important;
}
.wishlist__header {
    height: 30px !important;
    width: 100%;
}

.wishlist__header h2 {
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 24px !important;
    line-height: 125%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
}

.wishlist__items__container {
    padding: 0 0 24px !important;
}
}
.wishlist {
       align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 0 auto;
    margin: 0 auto !important;
    position: relative;
    min-height: 64vh;

}

.wishlist__container {
	height: auto;
    width: 100%;
}

.wishlist__header{
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-top: 24px;
	margin-bottom: 24px;

}

.wishlist__header>h2  {
    width: auto;
}

.wishlist__items__container {
align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0 0 auto;
    padding: 0 0 24px;
    min-height: 45vh;
    width: 100%;
}
.wishlist__items {
    width: 100%;
    min-height: 50px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.wishlist__item__label, .wishlist__items {
    align-content: center;
    align-items: start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fill, minmax(16.28889%, 1fr));
    justify-content: flex-start;
    width: 100%;
    justify-items: center;
}
.wish__card__container {
    width: 100%;   
}
.wishlist__header h2 {
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
}

.NotFound, .wish__card {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
}

	
.wish__card a.wish__item__image .product__image:hover {
	opacity:.9;
}
.wish__card a.wish__item__image .product__image {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    width: 100%;
    min-height: 380px;
}

.wish__card a.wish__item__image .product__image .product__img {
    width: 100%;
    position: absolute;
    top: 0;
    transition: transform 0.5s ease-in-out, opacity 0.8s ease-in-out;
    opacity: 0;
}

.wish__card a.wish__item__image .product__image .product__img.active {
    transform: translateX(0);
    opacity: 1;
}

.wish__card a.wish__item__image .product__image .product__img.left {
    transform: translateX(-100%);
    opacity: 0;
}

.wish__card a.wish__item__image .product__image .product__img.right {
    transform: translateX(100%);
    opacity: 0;
}
@media (max-width: 440px) {
	    .wish__card a.wish__item__image .product__image {
        min-height: 260px !important;
    }
	.wish__card__container {
        width: 48.222222%!important;
    }
	.wishlist__item__label, .wishlist__items {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        grid-template-columns: repeat(auto-fill, minmax(362px, 1fr));
        justify-content: space-between !important;
        width: 100%;
    }
}
@media (max-width: 1920px) {
.wish__card a.wish__item__image .product__image {
    cursor: pointer;
    min-height: 510px;
    overflow: hidden;
    position: relative;
    width: 100%;
}
}	
	@media (max-width: 1536px) {
.wishlist__item__label, .wishlist__items {
        align-content: flex-start;
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        grid-template-columns: repeat(auto-fill, minmax(362px, 1fr));
        justify-content: flex-start;
        width: 100%;
    }
.wish__card a.wish__item__image .product__image {
    cursor: pointer;
    min-height: 380px;
    overflow: hidden;
    position: relative;
    width: 100%;
}
}
	@media (max-width: 1336px) {
		    .wish__card a.wish__item__image .product__image {
        min-height: 497px;
    }
	}
	@media (max-width: 1440px) {
.wishlist__item__label, .wishlist__items {
        align-content: flex-start;
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        grid-template-columns: repeat(auto-fill, minmax(362px, 1fr));
        justify-content: flex-start;
        width: 100%;
    }
.wish__card a.wish__item__image .product__image {
    cursor: pointer;
    min-height: 380px;
    overflow: hidden;
    position: relative;
    width: 100%;
}
}

@media (min-width:744px) and (max-width:1024px) { 
    .wish__card a.wish__item__image .product__image {
        cursor: pointer;
        min-height: 500px;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
	.product__image>img {
        height: 100%;
        min-height: 100%;
        object-fit: contain !important;
    }
	.wishlist {
    min-height: 59vh;
	}
	.wish__card a.wish__item__image .product__image {
        cursor: pointer;
        min-height: 440px;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
}
@media (min-width:1024px) and (max-width:1336px) {   
 .product__image>img {
        height: 100%;
        min-height: 380px;
        object-fit: contain !important;
    }
	    .wish__card a.wish__item__image .product__image {
        cursor: pointer;
        min-height: 580px;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
	.wishlist {
    min-height: 64.5vh;
	}
}