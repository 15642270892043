.product__img.active {
    display: block;
}

.product__img.inactive {
    display: none;
}
@media (min-width: 768px) and (max-width: 1024px) {
    .carousel__item,
    .carousel__item, .slick-track .slick-slide {
        margin: 0 !important;
        max-width: 50% !important;
        min-width: 50% !important;
    }
}

@media (max-width: 1920px) {
    .related__product__image img {
        width: 100%;
    }
    .related__product__card__detail {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: flex-start;
        position: relative;
        width: 100%;
        margin-top: 8px;
    }
    .related__items__container .related__card__container .carousel .carousel__item .related__product__card__container .related__product__card__inner .product__card__action {
        right: 10% !important;
        top: 5% !important;
    }
    .related__product__card__container .related__product__card__inner .product__card__action {
        display: block;
        padding: 0;
        position: absolute;
        z-index: 10;
    }
    .related__product__image {
        height:512px;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
}


@media (max-width: 1440px) {
    .related__product__card__inner .product__card__action button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
        height: 19px;
    }
.related__product__image {
        height: 352px;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
}

@media (max-width: 1536px) {
a.related__product__link .related__product__image {
        height:330px;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
	.product__card__action button {
    margin:3px auto !important;
    padding: 0 !important;
}
.related__product__image {
        height: 384px;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
    .related__product__image img {
        width: 100%;
    }
    .related__product__card__detail {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: flex-start;
        position: relative;
        width: 100%;
        margin-top: 18px;
    }

    .related__product__card__container .related__product__card__inner .product__card__action {
        display: block;
        padding: 0;
        position: absolute;
        z-index: 10;
    }
}

@media (min-width: 440px) and (max-width: 1024px) {
    .related__items__container .related__card__container .carousel .carousel__item .related__product__card__container .related__product__card__inner .product__card__action {
        right: 12px !important;
        top: 7px !important;
    }
}
@media (min-width: 430px) and (max-width: 440px) {
    .related__items__container .related__card__container .carousel .carousel__item .related__product__card__container .related__product__card__inner .product__card__action {
        right: 12px !important;
        top: 5% !important;
    }
}
@media (min-width: 768px) and (max-width: 1440px) {

.related__product__image {
        height: 250px;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
	.product__card__action button {
    margin: 3px auto !important;
    padding: 0 !important;
}
.related__items__container .related__card__container .carousel .carousel__item .related__product__card__container .related__product__card__inner .product__card__action {
        right: 14px !important;
        top: 5% !important;
    }
}
@media (min-width: 768px) and (max-width: 820px) {    
a.related__product__link .related__product__image {
        height: 250px !important;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
}
@media (min-width: 820px) and (max-width: 1024px) {
	
a.related__product__link .related__product__image {
        height: 260px !important;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
}
@media (min-width: 1024px) and (max-width: 1336px) {
	
a.related__product__link .related__product__image {
        height:340px !important;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
}
@media (min-width: 290px) and (max-width: 440px) {
    .related__product__description, .related__product__name {
        font-size: 16px !important;
    }
    .related__product__card__container {
        height: auto !important;
        min-width: 100% !important;
        margin: 0;
    }    
	.related__product__image {
        height: 263px;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
	.product__card__action button {
        margin: 5px auto !important;
        padding: 0 !important;
}
    nav.breadcrumbs {
        margin: 0 0 40px;
        display: none;
    }
    .related__product__image {
        margin-top: 0 !important;
    }
    .related__product__image>img {
        max-width: 100% !important;
        margin-bottom: 0px;
        margin-top: 0px;
    }
    .carousel__item {
        flex: 0 0 auto !important;
        margin-right: 0 !important;
        max-width: 100% !important;
        width: 100%;
    }
.carousel__item, .slick-track .slick-slide {
        height: 100% !important;
        margin: 0px !important;
        min-width: 49.8%;
        position: relative;
        padding-right: 0px;
    }
	.featured__categories .carousel__item, .featured__categories .slick-track .slick-slide {
        margin: 0 !important;
        max-width: 100%;
        min-width: 100%;
    }
	.slick-dots {
    bottom: 41px !important;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative !important;
    text-align: center;
    width: 100%;
}
.slick-dots li, .slick-dots li button {
    cursor: pointer;
    height: 20px;
    width: 8px !important;
}
    a.carousel-control-next,
    a.carousel-control-prev {
        display: none; /* Скрыть элементы управления каруселью */
    }
}

.related__product__card__container {
    position: relative;
}

.related__product__card__inner {
    height: 100%;
    width: 100% ;
    color: black;
    text-decoration: none;
}

.related__product__card:hover {
    color: black;
    text-decoration: none;
}

.related__product__image {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column !important;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 12px;
    transition: 1s;
}

.related__product__name a,
.related__product__price span {
    font-size: 18px !important;
}

.related__product__description, 
.related__product__name {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'NexaWebRegular';
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
}

.related__product__description {
    height: 20%;
    font-size: 1rem;
    text-align: center;
}

.related__product__price {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'NexaWebRegular';
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
}
.product__card__action button {
    margin: 6px auto;
    padding: 0;
    width: 24px;
}
.related__product__card__inner .product__card__action button {
    margin: 0px auto;
    padding: 0;
    width: 24px;
}

@media (min-width: 376px) and (max-width: 440px) {
	    a.related__product__link .related__product__image {
        height: 260px !important;
    }
	    .related__product__card__detail {
        margin-top: 0px !important;
    }
    .related__card__container .carousel .carousel__item .related__product__card__container .related__product__card__inner .related__product__image>img {
        width: 100% !important;
		margin: 0;
    }
    .related__card__container .carousel .carousel__item .related__product__card__container {
        margin: 0;
        min-width: 100% !important;
    }
    .related__card__container {
        margin: 24px auto !important;
    }
    .related__items__container {
        margin-bottom: 40px !important;
    }
    .carousel {
        grid-gap: 26px !important;
        gap: 26px !important;
    }
    .featured__categories .slick-slide, .featured__categories .slick-slider {
        margin: 0 !important;
        max-width: 100%;
        min-width: 100%;
    }
}

@media (max-width: 1440px) {
	

.product__card__action button {
    margin: 0px auto;
    padding: 0;
    width: 24px;
}
}
@media (max-width: 375px) {
    .carousel {
        grid-gap: 25px !important;
        gap: 25px !important;
    }
}
.related__product__card__container.out-of-stock {
    opacity: 0.6;
    pointer-events: none;
}

.out-of-stock-overlay {
	display: none !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #ff0000;
}

.out-of-stock-text {
    color: #ff0000;
}