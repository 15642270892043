.input-container.error input,
.input-container.error textarea {
    border-bottom: 1px solid #FF3B30;
}
p.succespromo {
    position: absolute;
    font-size: 12px;
    background: none;
    border: none;
    margin-top: 40px !important;
}

.error-message {
    color: #FF3B30;
    font-size: 0.75em;
    position: absolute;
    top: 3.9em;
}
@media (max-width: 440px) {

.delivery-options .delivery-name label.custom-radio {
    font-size: 12px !important;
    height: 35px;
}
.payment-method h2, .pack-method h2 {
    font-size: 24px !important;
    text-transform: lowercase !important;
}
.Checkout .popup .popup-header {
    background-image: url('/public/media/popup.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 117px;
    line-height: normal;
    padding: 0;
    position: relative;
    text-align: center;
    width: 100%;
}
}

@media (max-width:1920px) {
	.Checkout .popup-overlay .popup-content {
    width: 40% !important;
}
	
.Checkout .popup .popup-header {
    background-image: url('/public/media/popup.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: auto;
    display: block;
    height: 145px;
    line-height: normal;
    padding: 0;
    position: relative;
    text-align: center;
    width: 100%;
}
button.closepopups {
    align-content: center;
    align-items: center;
    background: #1b1b1b;
    border: none;
    color: #fff;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-grow: 0;
    gap: 10px;
    height: 48px;
    margin-bottom: 20px;
    justify-content: center;
    padding: 0 16px;
    bottom: -10px;
    position: absolute;
    text-transform: uppercase;
    width: 94%;
}
}
@media (max-width:393px) {
	
.delivery-options .delivery-name label.custom-radio {
    font-size: 12px !important;
    height: 35px;
}
}
.mobile-input {
    display: none;
}
@media (min-width:290px) and (max-width:900px) {
	
.Checkout__content p {
    margin-bottom: 1rem;
    margin-top: 0;
    font-size: 12px;
    color: #BBBBBB;
}
.delivery-options .delivery-name label.custom-radio {
font-size: 12px !important;
        height: 35px;
        margin: 0;
}
	.checkout-summary, .delivery-options, .promo-code {
    margin: 24px 0 24px!important;
}
.Checkout__content form {
    float: left;
    width: 100%;
}
.prc {
    height: 22px !important;
}
a.ymaps3x0--map-copyrights__user-agreements {
    font-size: 12px !important;
}
textarea {
        font-size: 16px !important;
        font-weight: 400 !important;
        height: 55px !important;
}
input::placeholder, textarea::placeholder {
    font-size: 16px !important;
	color: #BBBBBB !important;
  }
input[type=email], input[type=number], input[type=radio], input[type=tel], input[type=text] {
    height: 54px !important;
}
.toamount, .toprice {
    font-size: 16px !important;
}
    .Checkout__container {
        margin-bottom: 24px !important;
    }
    .Checkout__container nav.breadcrumbs {
        display: none;
	}
		.Checkout__container h1 {
    position: relative;
    padding: 24px 0 0 0;
		}
		.contact-info h2, .pickup-point h3 {
    font-size: 24px !important;
}
.product-info .checkout-summary .prc, .product-info .checkout-summary .prm label.promo-code-label input[type=text] {
    border-bottom: 0px solid #ddd;
    font-size: 16px !important;
}
.Checkout__content {
        display: flex !important;
        flex-direction: column-reverse !important;
        align-content: center !important;
        justify-content: center !important;
        align-items: center !important;
        flex-wrap: nowrap !important;
        padding: 0 !important;
        grid-gap: 0 !important;
        gap: 0 !important;
}
.Checkout__container h1 {
    padding: 0 16px;
    margin: 24px 0;
    width: 100%;
    position: relative;
	font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 24px !important;
}
.prm, .final-amount, .delivery-amount {
    display: none !important;
}
.Checkout__container h1 {
    position: relative;
    padding: 24px 0!important;
}
.toamount, .toprice {
    font-size: 16px;
}
.product-details p, input[type=email], input[type=number], input[type=radio], input[type=tel], input[type=text] {
    flex-grow: 0;
    font-style: normal;
    font-weight: 400;
    font-family: NexaWebRegular;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    flex: none;
    order: 0;
}
.Checkout__container {
    margin: 24px 0 120px;
    padding: 0;
}
.delivery-options input[type="text"]::placeholder {
    font-size: 16px; /* Установите нужный размер шрифта */
  }
}
label.custom-radio {
    font-family: NexaWebRegular;
    font-size: 18px;
}
.delivery-options input[type="text"]::placeholder {
    font-size: 18px; /* Установите нужный размер шрифта */
  }
.checkout-summary, .delivery-options, .promo-code {
    margin: 24px 0;
}
label.custom-radio {
    font-size: 18px ;
}
.product-info {
    width: 100%;
    margin: 40px auto;
}
.Checkout__container h1, .contact-info h2, .pickup-point h3 {
    font-weight: 400;
    font-size: 24px;
    font-family: 'NexaWebRegular';
    line-height: 125%;
    text-transform: lowercase;
    color: #1b1b1b;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.Checkout {
    min-height: 100%;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    align-content: flex-start;
    margin: 0 auto !important;
}
.Checkout__content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-start;
    gap: 153px;
}
.checkout button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root {
    color: #fff;
    text-align: center;
    margin: 0 auto;
    width: 100%;
	font-size: 14px;
    font-family: NexaWebRegular;
    text-transform: uppercase;
    font-weight: 400;
}
.Checkout__content {
    align-content: center;
    margin-top: 0px;
}
.Checkout__container {
    height: auto;
    width: 100%;
}
.Checkout__container h1 {
    height: 34px;
    font-weight: 400;
    font-size: 27px;
	font-family: 'NexaWebRegular';
    line-height: 125%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 40px;
}
.contact-info p.border {
	font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px;
    text-transform: math-auto;
    gap: 10px;
    border: 1px solid #1B1B1B;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
	text-transform: none;
}

input::placeholder, textarea::placeholder {
    font-size: 16px !important;
	color: #BBBBBB !important;
  }
.contact-info h2, .pickup-point h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
	font-family: 'NexaWebRegular';
    line-height: 125%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.product-details button {
    border: 0;
    background: 0;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    margin: 0 auto;
    width: 48px;
    height: 16px;
    flex: none;
    order: 1;
    flex-grow: 0;
    width: 48px;
    height: 16px;
	font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 135%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #BBBBBB;
    flex: none;
    order: 0;
    flex-grow: 0;
}
nav.breadcrumbs {
    margin: 0 0 20px;
}
.info {
    margin: 12px 0;
}
.info p {
    height: 16px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 135%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #BBBBBB;
    flex: none;
    order: 2;
    flex-grow: 0;
}
.checkout-summary {
    margin: 16px 0;
}
.checkout-summary {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}
.prc {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 57px;
    border-bottom: 1px solid #ddd;
}
.prc, .prm label.promo-code-label input[type=text] {
    border-bottom: 1px solid #ddd;
    font-size: 18px;
}
.prm {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}
.prm label.promo-code-label {
    width: 100%;
}
.pack-amount {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 57px;
    border-bottom: 1px solid #ddd;
}
.delivery-amount {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 57px;
    border-bottom: 1px solid #ddd;
}
.final-amount {
    width: 100%;
    display: flex;
    height: 57px;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
}
.delivery-amount .toprice {
    color: #bbb;
    font-size: 18px;
}
.toamount {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    text-align: right;
    font-size: 18px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 1;
}
.toprice {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 1;
}
.checkout-summary p {
    width: 100%;
    align-content: flex-start;
    flex-wrap: nowrap;
    align-items: flex-start;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    padding: 16px 0;
    grid-gap: 10px;
    gap: 10px;
    height: 54px;
    width: 89%;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.checkout-summary p, .Checkout__content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
}
.contact-info {
    flex: 1 1;
    margin-right: 0;
    width: 100%;
}
.product-item {
    display: flex; /* Используем flexbox для выравнивания элементов */
    align-items: center; /* Вертикальное выравнивание */
    margin-bottom: 15px; /* Отступ между товарами */
}

.product-image {
    width: 64px;
    height: 88px;
    margin-right: 10px;
    object-fit: cover;
}
.product-details {
    flex: 1; /* Заполнение оставшегося пространства */
}
.product-info {
    flex: 1;
    margin-left: 20px; /* Отступ между колонками */
    margin-bottom: 30px;
}

.product-item {
    border-bottom: 1px solid #eee;
    padding: 10px 0;
}

.product-item p {
    margin: 5px 0;
}

.product-total {
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;
}

.delivery-options,
.promo-code,
.checkout-summary {
    margin-bottom: 30px;
}

label {
    display: block;
    margin:0;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="number"],
input[type="radio"] {
    width: calc(100% - 20px);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 0px;
    height: 57px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    border-left-width: 0;
    border-bottom: 1px solid #1b1b1b;
    border-right-width: 0;
    border-top-width: 0;
    font-style: normal;
    font-weight: 400;
	font-family: 'NexaWebRegular';
    font-size: 16px;
    line-height: 140%;
	    width: 100%;
    font-feature-settings: 'pnum' on, 'lnum' on;
        color: #1b1b1b;
    flex: none;
	border-radius: 0 !important;
    order: 0;
}
.product-details p {
    margin: 0 auto;
    height: 22px;
	font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 0;
}
input[type="radio"] {
    width: auto;
    margin-right: 10px;
}
input::placeholder {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%; /* Может быть 25px, но в зависимости от шрифта это может измениться */
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #BBBBBB;
}
.checkout-button {
    grid-gap: 10px;
    align-items: center;
    background: #1b1b1b;
    border: 0;
    color: #fff;
    font-family: NexaWebRegular;
    font-size: 14px;
    font-weight: 400;
    gap: 10px;
    height: 48px;
    justify-content: center;
    padding: 12px 0;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 16px;    
	margin-top: 20px;
}
.delivery-name p {
    margin: 24px 0px 0px 0px;
}
p.border {
    margin-bottom: 16px;
}
.checkout-button:hover {
    width: 100%;
	font-family: 'NexaWebRegular';
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    grid-gap: 10px;
    gap: 10px;
    height: 48px;
	color: #fff;
    background: #10059f;
    text-transform: uppercase;
    font-weight: 400;
}
.checkout-summary h2 {
    font-size: 20px;
}

.checkout-summary p {
    margin: 5px 0;
}

.footer-text {
    margin-top: 20px;
	font-family: 'NexaWebRegular';
    font-size: 14px;
    text-align: center;
    color: #666;
}
.checkout-summary, .delivery-options, .promo-code {
    margin: 24px 0;
}
.product-info {
    width: 100%;
    margin: 0px auto;
}
.product-info a.return {
    width: 100%;
    display: block;
}
.contact-info h2 {
    margin: 0 0 24px;
}
.contact-info label {
    margin: 0;
}
.delivery-name label {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 16px 0 0;
    height: 25px;
}
.delivery-name {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
}
.delivery-name p {
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 135%;
    text-transform: unset;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #bbb;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 9px 0 0;
}

.delivery-name label input[type="radio"] {
    padding: 0;
    margin: 0 16px 0 0;
    height: 25px;
}
.contact-info p.sityname {
color: #1b1b1b;
}
.contact-info p {
	font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 135%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #BBBBBB;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 16px 0 0 0;
}
.Checkout__container {
    margin: 0 0 60px 0;
    padding: 0;
}
.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-radio input[type="radio"] {
  display: none;
}

.radio-btn {
  width: 16px;
  height: 16px;
  background: #10059F;
  border-radius: 100px;
  margin-right: 10px;
  transition: background 0.3s; 
}
.custom-radio input[type=radio]:checked+.radio-btn, .radio-btn {
    background: #fff;
    border: 2px solid #bfbfbf;
}
.custom-radio input[type="radio"]:checked + .radio-btn::after {
    content: "";
    width: 12px;
    height: 12px;
    background: #10059f;
    border-radius: 50%;
    position: relative;
    top: 50%;
    display: block;
    left: 50%;
    transform: translate(-50%, -50%);
}
.delivery-name {
    margin: 24px 0 0px;
}
.Checkout__content p.border {
    font-size: 16px;
    padding: 10px;
    margin-top: 24px;
}
.Checkout__content p.pay {
    color: #bbb;
    font-size: 12px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 135%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #BBBBBB;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.payment-method p {
    margin: 24px 0 49px 0;
}
.custom-radio {
  flex: none;
  order: 0;
  flex-grow: 0;
}
textarea {
font-feature-settings: "pnum" on, "lnum" on;
    align-items: center;
    align-self: stretch;
    border: none;
    border-bottom: 1px solid #1b1b1b;
    border-radius: 0;
    box-sizing: border-box;
    color: #1b1b1b;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    font-family: NexaWebRegular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    height: 57px;
    line-height: 140%;
    margin: 16px 0 0;
    padding: 16px 0;
    width: 100%;
}

textarea:focus {
    outline: none;
    box-shadow: none;
}
input:focus {
    outline: none;
    box-shadow: none;
}

.payment-method {
    margin: 20px auto;
}
.pack-method {
    margin: 20px auto;
}
button.search-btn {
    border: 0;
    background: none;
    top: -40px;
    display: block;
    position: relative;
    float: right;
}
label.custom-radio {
    height: 40px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 15.5px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1b1b1b;
    flex: none;
    order: 1;
    flex-grow: 0;
}
#cdek-map {
    width: 100%;
    height: 600px;
    margin: 24px 0;
}
.Checkout__container h1 {
    position: relative;
    padding: 0px 0 0;
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 115%;
    letter-spacing: -.01em;
    text-transform: lowercase;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
}
.Checkout__content {
    padding: 40px 0 0 0 !important;
}.Checkout__content form {
    width: 50%;
    float: left;
}ul.suggestions {
    list-style: none;
    padding: 0;
    font-size: 14px;
    margin: 16px auto;
}
ul.suggestions li {
    padding: 5px;
    background: #dddddd1c;
    margin: 5px auto;
    border-radius: 5px;
}
label.custom-radio {
    margin: 0;
}
.product-details p, input[type=email], input[type=number], input[type=radio], input[type=tel], input[type=text] {
    margin: 16px 0 !important;
}	.input-container.promo {
    display: none;
}
@media (max-width: 1536px) {
.delivery-amount .toprice {
    color: #bbb;
    font-size: 16px !important;
}
.prc, .prm label.promo-code-label input[type=text], .toamount, .toprice {
    font-size: 16px !important;
}
	svg.prompsvg {
    position: absolute;
    right: 200px;
    width: 18px !important;
}
	.product-details p, input[type=email], input[type=number], input[type=radio], input[type=tel], input[type=text] {
    margin: 16px 0 !important;
}
.Checkout__container h1 {
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    font-family: NexaWebRegular;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
}
label.custom-radio {
    margin: 0px 0;        
	font-size: 15.5px !important;
}
.Checkout__content {
    gap: 113px;
}
label {
    display: block;
    margin: 0;
}
    .contact-info p {
color: #dfdfdf;
        margin: 8px 0 0;
        position: relative;
        text-transform: inherit;
    }
}
.payment-method p {
    position: relative;
    top: 2px;
    display: block;
    margin: 0 auto;
    height: 10px;
    width: 100%;
    font-size: 12px;
    opacity: 1;
    color: #BBBBBB;
    z-index: 999;
}
@media (max-width: 1920px) {	
label.custom-radio {
 
    font-size: 18px;

}
}
@media (max-width: 1440px) {	
.product-details p, input[type=email], input[type=number], input[type=radio], input[type=tel], input[type=text] {
    margin: 16px 0 !important;
}
.Checkout__container h1 {
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    font-family: NexaWebRegular;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
}
label.custom-radio {
    margin:0;
}
.Checkout__content {
    gap: 113px;
}
label {
    display: block;
    margin: 0;
}
.contact-info p {
    margin: 12px 0 0 0;
}
}
@media (max-width: 1366px) {
.Checkout__container h1 {
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    font-family: NexaWebRegular;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
}
    label.custom-radio {
        font-size: 18px;
        margin: 0;
        height: 35px;
    }
label {
    display: block;
    margin: 0;
}
.Checkout__content {
    gap: 113px;
}
    .contact-info p {
        margin: 0;
        padding: 0;
    }
}
.Checkout__content .product-info .cart__empty {
    margin: 0 auto;
}
@media (max-width: 430px) {
	.desctop-input {
    border: none;
    display: none !important;
    height: 128px;
}
.Checkout .popup-overlay .popup-content {
    align-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0;
    display: flex
;
    flex-direction: column;
    height: 293px;
    min-height: 204px;
    justify-content: flex-start;
    left: 0;
    margin: 0 auto;
    opacity: 1;
    padding: 0 16px;
    position: fixed;
    text-align: center;
    top: 100vh !important;
    width: 100%;
	max-width: 100% !important;
	max-height: 100% !important;
	
}
.Checkout .popup-overlay .popup-content h2 {
    font-size: 1.7rem !important;
}
.Checkout .popup-overlay .popup-content p {
    font-size: 1rem !important;
    padding: 10px 36px 20px 36px !important;
}
}
@media (max-width: 375px) {
	
label.custom-radio {
    font-size: 12px !important;
}
label.custom-radio {
    height: 35px !important;
}
}
@media (max-width: 768px) {
	.card_to {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: center;
    margin: 0px 0;
    padding: 0;
    text-align: left;
    width: 75%;
    gap: 5px;
}
.Checkout__content p.border {
    font-size: 14px;
    margin-top: 24px;
    padding: 20px;
}
	.contact-info p.sityname {
    color: #1b1b1b;
    margin: 16px auto;
}
	.delivery-amount .toprice, .toamount, .toprice {
    font-size: 16px !important;
}
label.custom-radio {
 
    font-size: 12px !important;
}
	.mobile-input {
    display: flex !important;
	gap: 11px;
    justify-content: space-between;
}
	.input-container.promo {
    display: block;
}
	svg.prompsvg {
    position: absolute;
    right: 200px;
    width: 18px;
}
	.Checkout__content .product-info .cart__empty {
    margin: 0 auto;
}
    .Checkout__content form {
        float: left;
        width: 100% !important;
    }
	.delivery-amount-to, .final-amount-to {
    align-content: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 57px;
    justify-content: center;
    width: 100%;
}
}
.input-container {
    position: relative;
    margin-bottom: 20px;
}

.input-container label {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    transition: all 0.3s ease;
    pointer-events: none;
    font-size: 16px;
    color: #BBBBBB;
}

.input-container input:focus + label,
.input-container input:not(:placeholder-shown) + label,
.input-container textarea:focus + label,
.input-container textarea:not(:placeholder-shown) + label {
    color: #BBBBBB;
    font-size: 12px;
    left: 0px;
    top: 0px;
}

.input-container textarea {
box-sizing: border-box;
    height: 54px;
    line-height: 54px;
    margin: 16px 0 !important;
    padding: 0;
    resize: none;
    border-radius: 0px;
    width: 100%;
}
.input-container svg.prompsvg {
    background: none;
    box-shadow: none;
    position: absolute;
    right: 0;
    top: 20px;
}
svg.prompsvg {
    position: absolute;
    right: 200px;
    width: 24px;
}
.delivery-options .input-container p {
    color: #1b1b1b;
    position: absolute;
    top: 59px;
}
.desctop-input {
border: none;
    display: block;
    height: 128px;
}
.delivery-amount-to, .final-amount-to{
 display: none;
    border: none;    
	height: 22px;
}
.final-amount-to{
 margin-bottom: 40px;
}
.checkout:hover {
    background: #10059f;
}
.Checkout .popup-overlay {
    background-color: rgb(255 255 255);
    display: block;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.Checkout .popup-overlay .popup-content {
    align-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    height: 293px;
    min-height: 204px;
    justify-content: flex-start;
    left: 0;
    margin: 0 auto;
    opacity: 1;
    padding: 0;
    position: fixed;
    text-align: center;
    top: 750px;
    width: 100%;
}
.Checkout .popup-overlay .popup-header h2 {
    position: absolute;
    top: 52px;
    text-align: center;
    width: 100%;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 125%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
}
.Checkout .popup-overlay .popup-content p {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    text-align: center;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #BBBBBB;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    padding: 20px 36px;
    margin: 0 auto;
}
button.popup-close-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    gap: 10px;
    position: relative;
    background: #1B1B1B;
    margin: 0 auto;
    width: 94%;
    height: 48px;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    text-align: center;
    font-family: 'NexaWebRegular';
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    flex: none;
    margin-bottom: 20px;
    order: 0;
    flex-grow: 0;
    align-content: center;
}
button.popup-close-button:hover {
	background: #10059f;
}

